.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  font-family: 'Geologica, Helvetica'
}


@font-face {
  font-family: "CraftworkGrotesk";   /*Can be any text*/
  src: local("CraftworkGrotesk"),
    url("./fonts/CraftworkGrotesk-Heavy.ttf") format("truetype");
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.MuiDataGrid-root .MuiDataGrid-cell {
  white-space: normal !important;
  font-weight: 300;
  word-wrap: break-word !important;
}

a,
a:hover,
a:active,
a:visited,
a:focus {
  text-decoration: none;
}

a:link, a:visited {
  text-decoration: none;
}

a {
  color: inherit;
}

.center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

nav.MuiPagination-root.MuiPagination-text {
  margin: .4;
  margin-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 5px;
  padding-bottom: 0.3em;
}

button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-text.MuiPaginationItem-circular.Mui-selected.MuiPaginationItem-page {
  border-radius: 4px;
  background-color: white;
  border-color: gray;
  border-style: solid;
  border-width: 2px;
  color: rgb(255, 121, 45);
  padding: 25px;
  font-size: 20px;
}

button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-text.MuiPaginationItem-circular.MuiPaginationItem-page {
  border-radius: 4px;
  background-color: white;
  border-color: gray;
  border-style: solid;
  border-width: 2px;
  padding: 25px;
  font-size: 20px;
  color: rgb(74, 78, 105);
}


@media (max-width: 960px) {
  .aa {
    margin-bottom: 1em;
  }
}

.min-time-end-adornment p{
  font-family: 'Roboto, Helvetica';
  font-size: 20px;
}

.col-capt-end {
  margin-left: -3px;
  width: 220px;
  margin-top: 20px;
  border-bottom: 3px solid #4a4e69;
  border-left: 3px solid #4a4e69;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-right: 3px solid #4a4e69;
}

